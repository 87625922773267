import React from 'react'
import ContactHero from '../../Components/contact-us-page-components/ContactHero'

export default function ContactPage() {
  return (
    <>
    <ContactHero />
    </>
  )
}
