import React from 'react'

const ExhibitionBronzeAge = () => {
  return (
    <div>
      ExhibitionBrongeAge
    </div>
  )
}

export default ExhibitionBronzeAge
