import React from "react";
import TechnicalStaff from "../../Components/about-us-page-components/TechnicalStaff";

const AboutPageTechnical = () => {
  return (
    <>
      <TechnicalStaff />
    </>
  );
};

export default AboutPageTechnical;
