import React from "react";
import VideosGallery from "../../Components/gallery-page-components/VideosGallery";

export default function GalleryVideosPage() {
  return (
    <>
      <div className="min-h-screen">
        <VideosGallery />
      </div>
    </>
  );
}
