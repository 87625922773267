import ArticlesPage from "../Pages/articles-page/ArticlesPage";


const articlesRoutes = [
  {
    path: "/articles",
    element: <ArticlesPage />
  },

];

export default articlesRoutes;
