import React from 'react'
import ArticlesHeroSection from '../../Components/articles-page-components/ArticlesHeroSection'

export default function ArticlesPage() {
  return (
    <>
    <ArticlesHeroSection />
    </>
  )
}
